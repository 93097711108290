import React from 'react';
import { Typography, Paper } from '@mui/material';

const Home = () => {
  return (
    <Paper elevation={3} style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Home Page
      </Typography>
      <Typography variant="body1">
        Welcome to the Email Marketing application. Use the navigation bar to access different sections of the application.
      </Typography>
    </Paper>
  );
}

export default Home;
