import React, { useState, useEffect } from 'react';
import { TextField, Button, Paper, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import axios from 'axios';

const Domains = () => {
  const [domains, setDomains] = useState([]);
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationDialogOpen, setVerificationDialogOpen] = useState(false);
  const [addedDomain, setAddedDomain] = useState(null);
  const [publicKey, setPublicKey] = useState('');
  const [privateKey, setPrivateKey] = useState('');

  useEffect(() => {
    fetchDomains();
  }, []);

  const fetchDomains = async () => {
    try {
      const response = await axios.get('/api/domains');
      console.log('Response from /api/domains:', response.data);
      if (Array.isArray(response.data)) {
        setDomains(response.data);
      } else {
        console.error('Response data is not an array:', response.data);
        setDomains([]);
      }
    } catch (error) {
      console.error('Failed to fetch domains', error);
    }
  };

  const extractDomainFromEmail = (email) => {
    const domain = email.split('@')[1];
    return domain ? domain : '';
  };

  const getDkimAndSpfRecords = async (domain) => {
    try {
      const response = await axios.post('/api/domains/generate-dkim', { domain });
      return {
        dkimRecord: response.data.dkimRecord,
        spfRecord: response.data.spfRecord,
        publicKey: response.data.publicKey,
        privateKey: response.data.privateKey
      };
    } catch (error) {
      console.error('Failed to get DKIM and SPF records', error);
      return { dkimRecord: '', spfRecord: '', publicKey: '', privateKey: '' };
    }
  };

  const handleAddDomain = async () => {
    const domain = extractDomainFromEmail(email);

    if (!domain) {
      alert('Invalid email address');
      return;
    }

    try {
      const { dkimRecord, spfRecord, publicKey, privateKey } = await getDkimAndSpfRecords(domain);

      const response = await axios.post('/api/domains/send-verification', {
        domain,
        email,
        publicKey,
        privateKey,
        dkimRecord,
        spfRecord
      });
      
      // ???????? ?????????? ???? ??? ????? ???? ?????????????
      setAddedDomain(domain);
      setVerificationDialogOpen(true);

      console.log('Domain added:', response.data);
      fetchDomains();
    } catch (error) {
      console.error('Failed to send verification email:', error);
    }
  };

  const verifyEmail = async () => {
  console.log('Verifying email with code:', verificationCode);
  try {
    const response = await axios.post('/api/domains/verify', { domain: addedDomain, verificationCode });
    if (response.status === 200) {
      fetchDomains();
      setVerificationDialogOpen(false);
      setEmail('');
      setVerificationCode('');
      alert('Email verified successfully!');
    } else {
      alert('Verification failed. Please check the code and try again.');
    }
  } catch (error) {
    console.error('Failed to verify email', error);
    alert('Failed to verify email. Please try again.');
  }
};

  return (
    <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
      <Typography variant="h5" gutterBottom>
        Security Settings
      </Typography>
      <Box mb={2}>
        <TextField
          fullWidth
          label="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Box>
      <Button variant="contained" color="primary" onClick={handleAddDomain} fullWidth>
        Add Domain
      </Button>
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Domain</TableCell>
              <TableCell>SPF Record</TableCell>
              <TableCell>SPF Status</TableCell>
              <TableCell>DKIM Host</TableCell>
              <TableCell>DKIM Record</TableCell>
              <TableCell>DKIM Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(domains) && domains.length > 0 ? (
              domains.map((d) => (
                <TableRow key={d._id}>
                  <TableCell>{d.domain}</TableCell>
                  <TableCell>{d.spfRecord || 'N/A'}</TableCell>
                  <TableCell>{d.spfStatus ? 'Valid' : 'Invalid'}</TableCell>
                  <TableCell>{d.dkimHost || 'N/A'}</TableCell>
                  <TableCell>{d.dkimRecord || 'N/A'}</TableCell>
                  <TableCell>{d.dkimStatus ? 'Valid' : 'Invalid'}</TableCell>
                  <TableCell>{d.isVerified ? 'Verified' : 'Not Verified'}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7}>No domains found.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={verificationDialogOpen} onClose={() => setVerificationDialogOpen(false)}>
        <DialogTitle>Verify Domain</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Verification Code"
            fullWidth
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setVerificationDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={verifyEmail} color="primary">
            Verify
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default Domains;