import React, { useState, useEffect } from 'react';
import { TextField, Button, IconButton, Paper, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Delete } from '@mui/icons-material';
import axios from 'axios';
import * as XLSX from 'xlsx';

const ContactList = () => {
  const [contacts, setContacts] = useState([]);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState({
    email: '',
    group: '',
    status: ''
  });
  const [newContact, setNewContact] = useState({
    email: '',
    group: '',
    status: 'subscribed'
  });
  const [importedContacts, setImportedContacts] = useState([]);
  const [importGroup, setImportGroup] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      const response = await axios.get('/api/contacts');
      setContacts(response.data);
    } catch (error) {
      console.error('Failed to fetch contacts', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/contacts/${id}`);
      fetchContacts();
    } catch (error) {
      console.error('Failed to delete contact', error);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value
    });
  };

  const handleNewContactChange = (e) => {
    setNewContact({
      ...newContact,
      [e.target.name]: e.target.value
    });
  };

  const handleAddContact = async () => {
    if (!newContact.email || !newContact.group) {
      alert('Email and Group are required');
      return;
    }
    try {
      const response = await axios.post('/api/contacts', newContact);
      console.log('Response:', response);
      fetchContacts();
      setNewContact({ email: '', group: '', status: 'subscribed' });
    } catch (error) {
      console.error('Failed to add contact:', error.response ? error.response.data : error.message);
      alert('Failed to add contact. Please try again.');
    }
  };

  const handleImport = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const contacts = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const formattedContacts = contacts.map(row => ({
        email: row[0],
        group: '',
        status: 'subscribed'
      })).filter(contact => contact.email); // Фильтруем контакты без email
      setImportedContacts(formattedContacts);
      setOpen(true);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleImportSubmit = async () => {
    if (!importGroup) {
      alert('Please specify a group for the imported contacts.');
      return;
    }
    if (importedContacts.length === 0) {
      alert('No contacts to import.');
      return;
    }
    const contactsToImport = importedContacts.map(contact => ({
      ...contact,
      group: importGroup
    }));
    try {
      await axios.post('/api/contacts/import', contactsToImport);
      fetchContacts();
      setImportedContacts([]);
      setImportGroup('');
      setOpen(false);
      alert('Contacts imported successfully.');
    } catch (error) {
      console.error('Failed to import contacts:', error);
      alert('Failed to import contacts. Please try again.');
    }
  };

  const handleExport = () => {
    const ws = XLSX.utils.json_to_sheet(filteredContacts);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Contacts');
    XLSX.writeFile(wb, 'contacts.xlsx');
  };

  const filteredContacts = contacts.filter(contact =>
    (contact.email.includes(search)) &&
    (filter.email ? contact.email.includes(filter.email) : true) &&
    (filter.group ? contact.group.includes(filter.group) : true) &&
    (filter.status ? contact.status.includes(filter.status) : true)
  );

  return (
    <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
      <Typography variant="h5" gutterBottom>
        Contact List
      </Typography>
      <Box mb={2} display="flex" justifyContent="space-between">
        <TextField label="Search Email" value={search} onChange={handleSearch} fullWidth />
        <Button variant="contained" component="label" color="primary" style={{ marginLeft: '10px' }}>
          Import Contacts
          <input type="file" accept=".xlsx, .xls" hidden onChange={handleImport} />
        </Button>
        <Button variant="contained" color="secondary" onClick={handleExport} style={{ marginLeft: '10px' }}>
          Export Contacts
        </Button>
      </Box>
      <Box mb={2}>
        <Button variant="contained" color="primary" onClick={fetchContacts} fullWidth>Refresh List</Button>
      </Box>
      <Box mb={2} display="flex" justifyContent="space-between">
        <TextField name="email" label="Email" value={newContact.email} onChange={handleNewContactChange} fullWidth />
        <TextField name="group" label="Group" value={newContact.group} onChange={handleNewContactChange} fullWidth style={{ marginLeft: '10px' }} />
        <Button variant="contained" color="primary" onClick={handleAddContact} style={{ marginLeft: '10px' }}>Add Contact</Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Group</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <TextField
                  name="email"
                  label="Filter by Email"
                  value={filter.email}
                  onChange={handleFilterChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </TableCell>
              <TableCell>
                <TextField
                  name="group"
                  label="Filter by Group"
                  value={filter.group}
                  onChange={handleFilterChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </TableCell>
              <TableCell>
                <TextField
                  name="status"
                  label="Filter by Status"
                  value={filter.status}
                  onChange={handleFilterChange}
                  variant="outlined"
                  size="small"
                  select
                  fullWidth
                >
                  <MenuItem value="subscribed">Subscribed</MenuItem>
                  <MenuItem value="unsubscribed">Unsubscribed</MenuItem>
                </TextField>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredContacts.map(contact => (
              <TableRow key={contact._id}>
                <TableCell>{contact.email}</TableCell>
                <TableCell>{contact.group}</TableCell>
                <TableCell>{contact.status}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleDelete(contact._id)}><Delete /></IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="body1" style={{ marginTop: '10px' }}>
        Total Contacts: {filteredContacts.length}
      </Typography>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Import Contacts</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to import {importedContacts.length} contacts. Please specify the group for these contacts.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Group"
            type="text"
            fullWidth
            value={importGroup}
            onChange={(e) => setImportGroup(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleImportSubmit} color="primary">
            Add Contacts
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default ContactList;
