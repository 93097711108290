import React, { useState, useEffect } from 'react';
import { TextField, Button, MenuItem, Typography, Paper, Box, Select, InputLabel, FormControl, Checkbox, ListItemText } from '@mui/material';
import axios from 'axios';

const EmailSender = () => {
  const [emailData, setEmailData] = useState({
    campaignName: '',
    groups: [],
    senderEmail: '',
    subject: '',
    message: '',
    file: null,
  });
  const [groups, setGroups] = useState([]);
  const [domains, setDomains] = useState([]);
  const [verifiedEmails, setVerifiedEmails] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchGroups();
    fetchDomains();
    fetchVerifiedEmails();
  }, []);

  const fetchGroups = async () => {
    try {
      const response = await axios.get('/api/contacts/groups');
      setGroups(response.data);
    } catch (error) {
      console.error('Failed to fetch groups', error);
    }
  };

  const fetchDomains = async () => {
    try {
      const response = await axios.get('/api/domains');
      setDomains(response.data);
    } catch (error) {
      console.error('Failed to fetch domains', error);
    }
  };

  const fetchVerifiedEmails = async () => {
    try {
      const response = await axios.get('/api/verified-emails');
      setVerifiedEmails(response.data);
    } catch (error) {
      console.error('Failed to fetch verified emails', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmailData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleGroupChange = (event) => {
    const { value } = event.target;
    setEmailData(prevData => ({
      ...prevData,
      groups: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  const handleFileChange = (e) => {
    setEmailData(prevData => ({ ...prevData, file: e.target.files[0] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const senderDomain = emailData.senderEmail.split('@')[1];

    if (!domains.some(domain => domain.domain === senderDomain)) {
      alert('The sender email domain is not synchronized. Please use a valid domain.');
      setLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append('campaignName', emailData.campaignName);
      formData.append('groups', JSON.stringify(emailData.groups));
      formData.append('senderEmail', emailData.senderEmail);
      formData.append('subject', emailData.subject);
      formData.append('message', emailData.message);
      if (emailData.file) {
        formData.append('file', emailData.file);
      }

      const response = await axios.post('/api/send-email', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      alert('Email sent successfully');
    } catch (error) {
      console.error('Failed to send email', error);
      alert('Failed to send email. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
      <Typography variant="h5" gutterBottom>
        Send Email
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <TextField
            fullWidth
            label="Campaign Name"
            name="campaignName"
            value={emailData.campaignName}
            onChange={handleChange}
            variant="outlined"
          />
        </Box>
        <Box mb={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Groups</InputLabel>
            <Select
              multiple
              value={emailData.groups}
              onChange={handleGroupChange}
              renderValue={(selected) => selected.join(', ')}
              label="Groups"
            >
              {groups.length > 0 ? (
                groups.map((group) => (
                  <MenuItem key={group} value={group}>
                    <Checkbox checked={emailData.groups.indexOf(group) > -1} />
                    <ListItemText primary={group} />
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No groups found</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
        <Box mb={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Sender Email</InputLabel>
            <Select
              name="senderEmail"
              value={emailData.senderEmail}
              onChange={handleChange}
              label="Sender Email"
            >
              {verifiedEmails.length > 0 ? (
                verifiedEmails.map((email) => (
                  <MenuItem key={email} value={email}>
                    {email}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No verified emails found</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
        <Box mb={2}>
          <TextField
            fullWidth
            label="Subject"
            name="subject"
            value={emailData.subject}
            onChange={handleChange}
            variant="outlined"
          />
        </Box>
        <Box mb={2}>
          <TextField
            fullWidth
            label="Message"
            name="message"
            value={emailData.message}
            onChange={handleChange}
            variant="outlined"
            multiline
            rows={4}
          />
        </Box>
        <Box mb={2}>
          <Button variant="contained" component="label">
            Upload File
            <input type="file" hidden onChange={handleFileChange} />
          </Button>
          {emailData.file && (
            <Typography variant="body2" style={{ marginTop: '10px' }}>
              {emailData.file.name}
            </Typography>
          )}
        </Box>
        <Button type="submit" variant="contained" color="primary" disabled={loading}>
          {loading ? 'Sending...' : 'Send Email'}
        </Button>
      </form>
    </Paper>
  );
};

export default EmailSender;
