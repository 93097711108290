import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip } from '@mui/material';
import { CheckCircle, Error, Info, FileCopy, Delete } from '@mui/icons-material';
import axios from 'axios';

const Security = () => {
  const [domain, setDomain] = useState('');
  const [domains, setDomains] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  useEffect(() => {
    fetchDomains();
  }, []);

  const fetchDomains = async () => {
    try {
      const response = await axios.get('/api/domains');
      console.log('Response from /api/domains:', response.data);
      if (Array.isArray(response.data)) {
        setDomains(response.data);
      } else {
        console.error('Response data is not an array:', response.data);
        setDomains([]);
      }
    } catch (error) {
      console.error('Failed to fetch domains', error);
    }
  };

  const handleAddDomain = async () => {
    try {
      const response = await axios.post('/api/domains', { domain });
      setDomains([...domains, response.data]);
      setDomain('');
    } catch (error) {
      console.error('Failed to add domain', error);
    }
  };

  const handleGenerateSpf = async (domain) => {
    try {
      const response = await axios.post('/api/domains/generate-spf', { domain });
      const updatedDomain = response.data;
      setDomains(domains.map(d => d.domain === updatedDomain.domain ? updatedDomain : d));
      await handleCheckSpf(domain);
    } catch (error) {
      console.error('Failed to generate SPF', error);
    }
  };

  const handleGenerateDkim = async (domain) => {
    try {
      console.log(`Generating DKIM for domain: ${domain}`);
      const response = await axios.post('/api/domains/generate-dkim', { domain });
      const updatedDomain = response.data;
      console.log('DKIM generated:', updatedDomain);
      setDomains(domains.map(d => d.domain === updatedDomain.domain ? updatedDomain : d));
      await handleCheckDkim(domain);
    } catch (error) {
      console.error('Failed to generate DKIM', error);
    }
  };

  const handleCheckSpf = async (domain) => {
    try {
      const response = await axios.post('/api/domains/check-spf', { domain });
      const updatedDomain = response.data;
      console.log('Updated SPF status:', updatedDomain);
      setDomains(domains.map(d => d.domain === updatedDomain.domain ? updatedDomain : d));
    } catch (error) {
      console.error('Failed to check SPF', error);
    }
  };

  const handleCheckDkim = async (domain) => {
    try {
      console.log(`Checking DKIM for domain: ${domain}`);
      const response = await axios.post('/api/domains/check-dkim', { domain });
      const updatedDomain = response.data;
      console.log('Updated DKIM status:', updatedDomain);
      setDomains(domains.map(d => d.domain === updatedDomain.domain ? updatedDomain : d));
    } catch (error) {
      console.error('Failed to check DKIM', error);
    }
  };

  const handleOpenDialog = (domain) => {
    setSelectedDomain(domain);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setSelectedDomain(null);
  };

  const handleOpenConfirmDialog = (domain) => {
    setSelectedDomain(domain);
    setConfirmOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmOpen(false);
    setSelectedDomain(null);
  };

  const handleDeleteDomain = async () => {
    try {
      await axios.delete(`/api/domains/${selectedDomain._id}`);
      setDomains(domains.filter(d => d._id !== selectedDomain._id));
      setConfirmOpen(false);
      setSelectedDomain(null);
    } catch (error) {
      console.error('Failed to delete domain', error);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Copied to clipboard!');
    }, (err) => {
      console.error('Failed to copy: ', err);
    });
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Security Settings</Typography>
      <Box display="flex" alignItems="center" mb={2}>
        <TextField 
          label="Domain" 
          value={domain} 
          onChange={(e) => setDomain(e.target.value)} 
          variant="outlined"
          style={{ marginRight: '10px' }}
        />
        <Button variant="contained" color="primary" onClick={handleAddDomain}>Add Domain</Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Domain</TableCell>
              <TableCell>SPF Record</TableCell>
              <TableCell>SPF Status</TableCell>
              <TableCell>DKIM Host</TableCell>
              <TableCell>DKIM Record</TableCell>
              <TableCell>DKIM Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(domains) && domains.length > 0 ? (
              domains.map((domain) => (
                <TableRow key={domain._id}>
                  <TableCell>{domain.domain}</TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center" style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>
                      {domain.spfRecord || 'N/A'}
                      {domain.spfRecord && (
                        <Tooltip title="Copy SPF Record">
                          <IconButton onClick={() => copyToClipboard(domain.spfRecord)} size="small">
                            <FileCopy />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {domain.spfStatus ? <CheckCircle color="primary" /> : <Error color="error" />}
                  </TableCell>
                  <TableCell>{`${domain.domain}._domainkey.${domain.domain}`}</TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center" style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>
                      {domain.dkimRecord || 'N/A'}
                      {domain.dkimRecord && (
                        <Tooltip title="Copy DKIM Record">
                          <IconButton onClick={() => copyToClipboard(domain.dkimRecord)} size="small">
                            <FileCopy />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {domain.dkimStatus ? <CheckCircle color="primary" /> : <Error color="error" />}
                  </TableCell>
                  <TableCell>
                    <Button variant="contained" color="primary" onClick={() => handleGenerateSpf(domain.domain)}>Generate SPF</Button>
                    <Button variant="contained" color="primary" onClick={() => handleGenerateDkim(domain.domain)} style={{ marginLeft: '10px' }}>Generate DKIM</Button>
                    <IconButton onClick={() => handleOpenDialog(domain)} style={{ marginLeft: '10px' }}><Info /></IconButton>
                    <IconButton onClick={() => handleOpenConfirmDialog(domain)} style={{ marginLeft: '10px' }}><Delete /></IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7}>No domains found.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedDomain && (
        <Dialog open={open} onClose={handleCloseDialog}>
          <DialogTitle>Domain Details</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <strong>Domain:</strong> {selectedDomain.domain}<br />
              <strong>SPF Record:</strong> {selectedDomain.spfRecord || 'N/A'}<br />
              <strong>SPF Status:</strong> {selectedDomain.spfStatus ? 'Valid' : 'Invalid'}<br />
              <strong>DKIM Host:</strong> {`${selectedDomain.domain}._domainkey.${selectedDomain.domain}`}<br />
              <strong>DKIM Record:</strong> {selectedDomain.dkimRecord || 'N/A'}<br />
              <strong>DKIM Status:</strong> {selectedDomain.dkimStatus ? 'Valid' : 'Invalid'}
            </DialogContentText>
            <Typography variant="body1" gutterBottom>
              <strong>SPF Setup Instructions:</strong><br />
              {selectedDomain.spfRecord ? (
                <div>
                  Add the following SPF record to your DNS settings:<br />
                  <code>{selectedDomain.spfRecord}</code>
                </div>
              ) : 'SPF record not generated yet.'}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>DKIM Setup Instructions:</strong><br />
              {selectedDomain.dkimRecord ? (
                <div>
                  Add the following DKIM record to your DNS settings:<br />
                  <code>{selectedDomain.dkimRecord}</code>
                </div>
              ) : 'DKIM record not generated yet.'}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">Close</Button>
          </DialogActions>
        </Dialog>
      )}

      <Dialog
        open={confirmOpen}
        onClose={handleCloseConfirmDialog}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the domain <strong>{selectedDomain?.domain}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">Cancel</Button>
          <Button onClick={handleDeleteDomain} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Security;
