import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import Home from './components/Home';
import ContactList from './components/ContactList';
import Security from './components/Security';
import EmailSender from './components/EmailSender';
import Domains from './components/Domains';
import Statistics from './components/Statistics';

function App() {
  return (
    <Router>
      <div>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              Email Marketing
            </Typography>
            <Button color="inherit" component={Link} to="/">Home</Button>
            <Button color="inherit" component={Link} to="/contacts">Contact List</Button>
            <Button color="inherit" component={Link} to="/security">Security</Button>
            <Button color="inherit" component={Link} to="/send-email">Send Email</Button>
            <Button color="inherit" component={Link} to="/domains">Domains</Button>
            <Button color="inherit" component={Link} to="/statistics">Statistics</Button>
          </Toolbar>
        </AppBar>
        <div style={{ padding: '20px' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contacts" element={<ContactList />} />
            <Route path="/security" element={<Security />} />
            <Route path="/send-email" element={<EmailSender />} />
            <Route path="/domains" element={<Domains />} />
            <Route path="/statistics" element={<Statistics />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
